<template>
  <section>
    <div>
      <el-tabs
        :stretch="false"
        v-model="activeName"
        @tab-click="handleClick"
        class="Deribit-tabs"
      >
        <el-tab-pane label="BTC" name="BTC">
          <b-row>
            <b-col lg="12">
              <b-card no-body>
                <b-card-header>
                  <!-- <b-card-title class="fundsRate">
                    Deribit 期权大宗交易
                  </b-card-title> -->
                </b-card-header>
                <b-card-body class="tabletrade">
                  <div class="deribit-sel">
                    <el-form
                      :inline="true"
                      :model="formInline"
                      class="demo-form-inline"
                    >
                      <el-form-item label="最低交易额">
                        <el-input
                          v-model="formInline.price_amount"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="标的名">
                        <el-select
                          v-model="formInline.instrument_name"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="请输入关键词"
                          :remote-method="remoteMethod"
                          :loading="loading"
                          class="lump"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="买卖方向">
                        <el-select
                          v-model="formInline.direction"
                          placeholder="All"
                        >
                          <el-option label="All" value=""></el-option>
                          <el-option label="Sell" value="sell"></el-option>
                          <el-option label="Buy" value="buy"></el-option>
                        </el-select>
                      </el-form-item>

                      <!-- <el-form-item label="iv范围">
                        <el-input
                          v-model="formInline.siv"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                        <span> - </span>
                        <el-input
                          v-model="formInline.eiv"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                      </el-form-item> -->

                      <el-form-item label="起止时间">
                        <el-date-picker
                          v-model="formInline.crrateTime"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="GetTime"
                          class="crrateTime"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="primaryclick(1)"
                          >查询</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="aErr-overview" v-show="BErrShow">暂无数据</div>
                  <div class="table-show" v-show="BTableShow">
                    <el-table
                      v-if="tableData.length > 0"
                      v-loading="isLoading"
                      element-loading-background="rgba(0, 0, 0, 0.5)"
                      element-loading-text="加载中，请稍后..."
                      element-loading-spinner="el-icon-loading"
                      :data="tableData"
                      style="width: 100%; background-color: transparent"
                      :row-style="{ height: '60px' }"
                      class="deribit-table"
                      :row-class-name="tableRowClassName"
                      :default-sort="{ prop: 'time', order: 'descending' }"
                    >
                      <div slot="empty" style="text-align: left">等待数据</div>

                      <el-table-column prop="symbol" label="标的名">
                      </el-table-column>
                      <el-table-column prop="side" label="交易方向">
                      </el-table-column>
                      <el-table-column
                        prop="time"
                        sortable
                        :formatter="gridDateFormatter"
                        label="交易时间"
                      >
                      </el-table-column>
                      <el-table-column prop="orderQty" label="成交数量(个）">
                      </el-table-column>
                      <el-table-column prop="orderPrice" label="成交价(USD)">
                      </el-table-column>
                      <el-table-column
                        prop="orderAllPrice"
                        label="交易额(USD)"
                        :formatter="matter"
                      >
                      </el-table-column>
                    </el-table>
                    <div class="block">
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="pageParams.currentPage"
                        :page-sizes="[5, 10, 20, 50, 100]"
                        :page-size="pageParams.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="pageParams.total"
                      >
                      </el-pagination>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </el-tab-pane>

        <el-tab-pane label="ETH" name="ETH">
          <b-row>
            <b-col lg="12">
              <b-card no-body>
                <b-card-header>
                  <!-- <b-card-title class="fundsRate">
                    Deribit 期权大宗交易
                  </b-card-title> -->
                </b-card-header>
                <b-card-body class="tabletrade">
                  <div class="deribit-sel">
                    <el-form
                      :inline="true"
                      :model="formInline"
                      class="demo-form-inline"
                    >
                      <el-form-item label="最低交易额">
                        <el-input
                          v-model="formInline.price_amount"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="标的名">
                        <el-select
                          v-model="formInline.instrument_name"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="请输入关键词"
                          :remote-method="remoteMethod"
                          :loading="loading"
                          class="lump"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="买卖方向">
                        <el-select
                          v-model="formInline.direction"
                          placeholder="All"
                        >
                          <el-option label="All" value=""></el-option>
                          <el-option label="Sell" value="sell"></el-option>
                          <el-option label="Buy" value="buy"></el-option>
                        </el-select>
                      </el-form-item>

                      <!-- <el-form-item label="iv范围">
                        <el-input
                          v-model="formInline.siv"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                        <span> - </span>
                        <el-input
                          v-model="formInline.eiv"
                          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                          placeholder="请输入数字"
                          class="iv"
                        ></el-input>
                      </el-form-item> -->

                      <el-form-item label="起止时间">
                        <el-date-picker
                          v-model="formInline.crrateTime"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="GetTime"
                          class="crrateTime"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="onSubmit(1)"
                          >查询</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="aErr-overview" v-show="EErrShow">暂无数据</div>
                  <div class="table-show" v-show="ETableShow">
                    <el-table
                      v-if="tableData1.length > 0"
                      v-loading="loading"
                      element-loading-background="rgba(0, 0, 0, 0.5)"
                      element-loading-text="加载中，请稍后..."
                      element-loading-spinner="el-icon-loading"
                      :data="tableData1"
                      style="width: 100%; background-color: transparent"
                      :row-style="{ height: '60px' }"
                      class="deribit-table"
                      :row-class-name="tableRowClassName"
                      :default-sort="{ prop: 'time', order: 'descending' }"
                    >
                      <el-table-column prop="symbol" label="标的名">
                      </el-table-column>
                      <el-table-column prop="side" label="交易方向">
                      </el-table-column>
                      <el-table-column
                        prop="time"
                        :formatter="gridDateFormatter"
                        sortable
                        label="交易时间"
                      >
                      </el-table-column>
                      <el-table-column prop="orderQty" label="成交数量(个）">
                      </el-table-column>
                      <el-table-column prop="orderPrice" label="成交价(USD)">
                      </el-table-column>
                      <el-table-column
                        prop="orderAllPrice"
                        label="交易额(USD)"
                        sortable
                        :formatter="matter"
                      >
                      </el-table-column>
                    </el-table>
                    <div class="block">
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="pageParams.currentPage"
                        :page-sizes="[5, 10, 20, 50, 100]"
                        :page-size="pageParams.pageSize"
                        layout="sizes, prev, pager, next"
                        :total="this.total"
                      >
                      </el-pagination>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import { getBtcOptionsRate } from "@/api/BlockTransactions.js";
import { getEthOptionsRate } from "@/api/BlockTransactions.js";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
  },
  data() {
    return {
      activeName: "BTC",
      optionsData: [],
      options: [],
      value: [],
      list: [],
      isLoading: false,
      loading: false,
      historyTitel: "",
      BTableShow: false,
      BErrShow: false,
      ETableShow: false,
      EErrShow: false,
      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      formInline: {
        price_amount: "5000",
        instrument_name: "",
        direction: "",
        s_iv: [],
        e_iv: [],
        siv: "",
        eiv: "",
        crrateTime: "",
        startTime: "",
        endTime: "",
        st: "",
        et: "",
      },
      total: 0,
      pageParams: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
      },
      // total: 0,
      tableData: [],
      tempEthData: [], //存放Deribit ETC全部数据
      tableData1: [],
      ivData: [],
      tempData: [], //存放Deribit BTC全部数据
    };
  },
  watch: {},
  created() {
    this.getListData();
    // console.log("states-->", this.states);
  },
  computed: {},
  mounted() {},
  methods: {
    sortMethod(before, after) {
      return Number(before.no) - Number(after.no);
    },
    toDecimal2(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    gridDateFormatter(row, column, cellValue, index) {
      const daterc = row[column.property];
      if (daterc) {
        const dateMat = new Date(daterc);
        const Y = dateMat.getFullYear() + "-";
        const M =
          dateMat.getMonth() + 1 < 10
            ? "0" + (dateMat.getMonth() + 1) + "-"
            : dateMat.getMonth() + 1 + "-";
        const D =
          dateMat.getDate() < 10
            ? "0" + dateMat.getDate() + " "
            : dateMat.getDate() + " ";

        const H =
          dateMat.getHours() < 10
            ? "0" + dateMat.getHours() + ":"
            : dateMat.getHours() + ":";

        const F =
          dateMat.getMinutes() < 10
            ? "0" + dateMat.getMinutes() + ":"
            : dateMat.getMinutes() + ":";

        const S =
          dateMat.getSeconds() < 10
            ? "0" + dateMat.getSeconds() + ""
            : dateMat.getSeconds() + "";

        return Y + M + D + H + F + S;
      }
    },

    //奇偶行背景色不同
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }

      return "evenRow";
    },
    //tab 被选中时触发
    handleClick(tab, event) {
      this.options = [];
      if (this.activeName === "BTC") {
        for (let i in this.formInline) {
          this.formInline.price_amount = "5000";
          this.formInline[i] = "";
        }
        this.getListData();
      } else {
        for (let i in this.formInline) {
          this.formInline.price_amount = "5000";
          this.formInline[i] = "";
        }
        this.getEthListData();
      }
    },
    //表格内容添加千位分割符保留两位小数

    matter(row, column, cellValue) {
      if (cellValue !== null) {
        cellValue = Number(cellValue).toFixed(2);
        cellValue += "";
        if (!cellValue.includes(".")) cellValue += ".";
        return cellValue
          .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
    //启动远程搜索，在输入值发生变化时调用，参数为当前输入值
    remoteMethod(query) {
      // if(this.activeName ==="BTC"){

      // }
      this.list = this.states.map((item) => {
        return { value: `${item}`, label: `${item}` };
      });

      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    sivChange(siv) {
      let eivArr = new Array();
      for (let i = siv + 1; i <= 500; i++) {
        eivArr.push(i);
      }
      this.formInline.e_iv = eivArr;
    },
    amounrData() {
      let arr = new Array();
      for (var i = 0; i <= 500; i++) {
        arr.push(i);
      }
      // console.log("111", arr);
      this.formInline.s_iv = arr;
      this.formInline.e_iv = arr;
      return arr;
    },
    // 获取期权bybit BTC期权标的所以数据
    getListData(val) {
      //请求之前，开启loading
      this.isLoading = true;
      if (val) {
        this.pageParams.currentPage = val || 1;
      }
      const params = {
        currency: "BTC",
        minPrice: this.formInline.price_amount,
        beginTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        symbol: this.formInline.instrument_name,
        side: this.formInline.direction,
        pageIndex: this.pageParams.currentPage,
        pageSize: this.pageParams.pageSize,
      };
      this.axios
        .get("https://trade.rta.academy/trade/bybitoption/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let tempData = res.data.data;
            // this.tableData = tempData;
            if (tempData.length != []) {
              this.BTableShow = true;
              this.BErrShow = false;
              this.pageParams.total = res.data.total;
              this.isLoading = false;
              this.tableData = tempData;
            } else {
              this.BTableShow = false;
              this.BErrShow = true;
              this.tableData = [];
              this.pageParams.total = 0;
              this.pageParams.pageSize = 20;
            }
          }
        })
        .catch((err) => {
          this.tableData = [];
          this.pageParams.total = 1;
          this.pageParams.pageSize = 20;
          //请求失败，关闭loading
          this.isLoading = false;
        });
    },
    // 获取期权Deribit ETH期权标的所以数据
    getEthListData(val) {
      if (val) {
        this.pageParams.currentPage = val || 1;
      }
      const params = {
        currency: "ETH",
        minPrice: this.formInline.price_amount,
        beginTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        symbol: this.formInline.instrument_name,
        side: this.formInline.direction,
        pageIndex: this.pageParams.currentPage,
        pageSize: this.pageParams.pageSize,
      };
      this.axios
        .get("https://trade.rta.academy/trade/bybitoption/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            // console.log(res);
            let tempEthData = res.data.data;
            if (tempEthData.length != []) {
              this.ETableShow = true;
              this.EErrShow = false;
              this.total = res.data.total;

              //请求成功，关闭loading
              this.isLoading = false;
              this.tableData1 = tempEthData;
            } else {
              this.ETableShow = false;
              this.EErrShow = true;
              this.tableData1 = [];
              this.total = 0;
              this.pageParams.pageSize = 20;
            }
          }
        })
        .catch((err) => {
          this.tableData1 = [];
          this.total = 0;
          this.pageParams.pageSize = 10;
        });
    },

    // 每页显示的记录数切换事件
    handleSizeChange(val) {
      this.pageParams.pageSize = val;
      this.tableData1 = [];
      this.tableData = [];
      if (this.activeName === "BTC") {
        this.getListData();
      } else {
        this.getEthListData();
      }
    },
    // 切换页码事件
    handleCurrentChange(val) {
      if (this.activeName === "BTC") {
        this.getListData();
      } else {
        this.getEthListData();
      }
      this.pageParams.currentPage = val;
      this.tableData1 = [];
      this.tableData = [];

      // console.log("当前页码：", val);
    },
    primaryclick(val) {
      this.tableData = [];
      this.getListData(val);
      // console.log("states...", this.states);
    },
    onSubmit(val) {
      this.tableData1 = [];
      this.getEthListData(val);
    },
    GetTime() {
      // console.log(this.formInline.crrateTime);
      // console.log("date");
      //清除后回显
      if (this.formInline.crrateTime == null) {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
      // 转时间戳
      if (this.formInline.crrateTime != null) {
        this.formInline.startTime = this.formInline.crrateTime[0];
        this.formInline.endTime = this.formInline.crrateTime[1];
      }
    },
  },
  filters: {
    dateFormat(dateStr) {
      let fmt = "yyyy-MM-dd";
      let date = new Date(dateStr);
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
  },
};
</script>
<style lang="scss">
@import "./scss/BlockTransactions/BlockTransactions.scss";

</style>